import React from "react";
import NavLi from "./NavLi";
import { useStaticQuery, graphql } from "gatsby";

function Nav({ navState, setNavState }) {
  const data = useStaticQuery(graphql`
    query StaticQueryNav {
      allMarkdownRemark(
        filter: { fields: { slug: { regex: "/^/[^/]+/$/" } } }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              h1
            }
          }
        }
      }
    }
  `);
  const edges = data.allMarkdownRemark.edges;
  return (
    <nav id="nav" className={`nav ${navState}`}>
      <ul className="nav__ul">
        <NavLi name="Aktuell" to="/aktuell"></NavLi>
        <NavLi name="Online Ausstellung" to="/ausstellung"></NavLi>
        <NavLi name="Biografie/ Publikationen" to="/biografie"></NavLi>
        <RepeatNavLi data={edges}></RepeatNavLi>
        <NavLi name="Kontakt" to="/kontakt"></NavLi>
      </ul>
    </nav>
  );
}

function RepeatNavLi({ data }) {
  const mapNavLi = data.map(entry => {
    const node = entry.node;
    const slug = node.fields.slug;
    const name = node.frontmatter.h1;
    return <NavLi cssClass="nav__li" name={name} to={slug} key={slug}></NavLi>;
  });
  return mapNavLi;
}

export default Nav;
