import React from "react";
import Layout from "./src/components/Layout/Layout";
import NavContextProvider from "./src/context/NavContextProvider";
import TitleContextProvider from "./src/context/TitleContextProvider";

// wrappes around all components
export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const wrapRootElement = ({ element }) => {
  return (
    <NavContextProvider>
      <TitleContextProvider>{element}</TitleContextProvider>
    </NavContextProvider>
  );
};
