import React from "react";
function NavTrigger({ navClasses, toggleMenu }) {
  //Nav logic
  return (
    <>
      <button
        className={navClasses}
        onClick={() => {
          toggleMenu();
        }}
      >
        <span className="line"></span>
        <span className="line"></span>
        <span className="line"></span>
      </button>
    </>
  );
}

export default NavTrigger;
