import { useContext } from "react";
import { NavContext } from "../../context/NavContextProvider";

/*
! HOW TO USE
    - State (global) in NavContext
    - logic in use Nav

    - to toggle:  
      - F toggle menu
    - to use:     
      - navClasses as CSS_Classes for nav
*/

function useNav() {
  const {
    hidden,
    active,
    navState,
    setNavState,
    navClasses,
    setNavClasses,
  } = useContext(NavContext);
  const none = `${hidden} nav__none`;
  const block = `${hidden} nav__block`;

  const toggleMenu = () => {
    const showNav = () => {
      setNavClasses("header__trigger checked");
      setNavState(block);
      setTimeout(() => {
        setNavState(active);
      }, 100);
    };
    const hideNav = () => {
      setNavClasses("header__trigger");
      setNavState(hidden);
      setTimeout(() => {
        setNavState(none);
      }, 300);
    };

    const addScrollToggle = () => {
      window.addEventListener(
        "scroll",
        () => {
          hideNav();
        },
        { once: true }
      );
    };
    const navIsToggled = navClasses === "header__trigger";

    if (navIsToggled) {
      showNav();
      addScrollToggle();
      //addClickToggle();
    } else {
      hideNav();
    }
  };

  return { active, toggleMenu, navState, navClasses };
}

export default useNav;
