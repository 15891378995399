import React from "react";
import useNav from "../Navigation/useNav";
import Nav from "../Navigation/Nav";
import NavTrigger from "../Navigation/NavTrigger";
import useTitleContext from "../../context/useTitleContext";
import Link from "../Navigation/Link";

function Header(data) {
  const title = useTitleContext();

  //toggle nav on every route change if active
  const { navState, setNavState, navClasses, toggleMenu } = useNav();
  const withoutH1 = title === "" && "--withoutH1";
  return (
    <>
      <Nav
        navState={navState}
        setNavState={setNavState}
        toggleMenu={toggleMenu}
      />
      <header className="header">
        <div className={`header__container${withoutH1} header__container`}>
          <Link to={"/"} className="header__logo">
            Florian Haas
          </Link>
          <NavTrigger navClasses={navClasses} toggleMenu={toggleMenu} />
          {title === "" ? null : <h1 className="header__h1">{title}</h1>}
        </div>
      </header>
    </>
  );
}
export default Header;
