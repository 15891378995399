import React from "react";
import Link from "./Link";
function NavLi({ name, to: route }) {
  return (
    <>
      <li className="nav__li">
        <Link to={route}>{name}</Link>
      </li>
    </>
  );
}

export default NavLi;
