import React, { createContext, useState } from "react";
/*
  necessary?
    because F. toggleMenu uses shared GLOBAL state which lives in this context
*/

export const NavContext = createContext("");

function NavContextProvider(props) {
  //vars
  const hidden = "nav__hidden";
  const active = "nav__active";
  const [navState, setNavState] = useState(`${hidden} nav__none`);
  const [navClasses, setNavClasses] = useState("header__trigger");
  return (
    <NavContext.Provider
      value={{
        hidden,
        active,
        navState,
        setNavState,
        navClasses,
        setNavClasses,
      }}
    >
      {props.children}
    </NavContext.Provider>
  );
}

export default NavContextProvider;
