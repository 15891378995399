import React, { createContext, useState } from "react";

export const TitleContext = createContext("");

function TitleContextProvider(props) {
  const [title, setTitle] = useState("");
  return (
    <TitleContext.Provider value={{ title, setTitle }}>
      {props.children}
    </TitleContext.Provider>
  );
}

export default TitleContextProvider;
