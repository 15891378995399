// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuell-2-js": () => import("./../../../src/pages/aktuell 2.js" /* webpackChunkName: "component---src-pages-aktuell-2-js" */),
  "component---src-pages-aktuell-js": () => import("./../../../src/pages/aktuell.js" /* webpackChunkName: "component---src-pages-aktuell-js" */),
  "component---src-pages-ausstellung-js": () => import("./../../../src/pages/ausstellung.js" /* webpackChunkName: "component---src-pages-ausstellung-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-templates-article-article-js": () => import("./../../../src/templates/article/Article.js" /* webpackChunkName: "component---src-templates-article-article-js" */),
  "component---src-templates-gallery-gallery-js": () => import("./../../../src/templates/gallery/Gallery.js" /* webpackChunkName: "component---src-templates-gallery-gallery-js" */),
  "component---src-templates-sub-menu-sub-menu-js": () => import("./../../../src/templates/subMenu/SubMenu.js" /* webpackChunkName: "component---src-templates-sub-menu-sub-menu-js" */),
  "component---src-templates-vita-vita-js": () => import("./../../../src/templates/vita/Vita.js" /* webpackChunkName: "component---src-templates-vita-vita-js" */)
}

