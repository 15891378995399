import React from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
//✨ (styles)
import "../../globalStyles/global.scss";

function Layout(props) {
  return (
    <>
      <div className="body">
        <Header location={props.location}></Header>
        {props.children}
      </div>
      <Footer></Footer>
    </>
  );
}

export default Layout;
