import { useContext, useEffect } from "react";
import { TitleContext } from "./TitleContextProvider";

function useTitleContext(newTitle) {
  const { title, setTitle } = useContext(TitleContext);

  useEffect(() => {
    newTitle ? setTitle(newTitle) : setTitle("");
  }, [newTitle, setTitle]);

  return title;
}

export default useTitleContext;
