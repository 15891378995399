import React from "react";
import Link from "../Navigation/Link";

function Footer() {
  return (
    <footer className="Footer">
      <Link to="/impressum">Impressum</Link>
      <Link to="/datenschutz">Datenschutz</Link>
      <Link to="/">Home</Link>
    </footer>
  );
}
export default Footer;
