import React from "react";
import { Link as RoutingLink } from "gatsby";
import useNav from "./useNav";

function Link({ className, to, children, activeClassName = null }) {
  const { active, toggleMenu, navState } = useNav();
  const isActive = navState === active;

  return (
    <RoutingLink
      className={className}
      activeClassName={activeClassName}
      to={to}
      onClick={() => {
        isActive && toggleMenu();
      }}
    >
      {children}
    </RoutingLink>
  );
}

export default Link;
